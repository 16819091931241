<template>
  <v-container fluid>

<body>

  <div >
     <h1><span class="title-color">Invoice</span></h1>
     <div id="logo">
            
              <img id="image" src="images/logo.png" alt="logo" />
            </div>

  </div>

<div >
  <li>
    <span>Invoice Number: {{}}</span>
   
  </li>
  <li>
    <span>Date:{{}} </span>
   
  </li>
  <li>
    <span>Time: {{}}</span>
  
  </li>
</div >


<div class="bill">
 <li>
    <span>Billed To: {{}}</span> <br>
  </li>
   <li>
    <span>Client Name : {{}}</span> <br>
  </li>
   <li>
    <span>Billing Address : {{}}</span> <br>
  </li>
   <li>
    <span>City: {{}}</span>
  </li>
  <li>
    <span>Phone Number: {{}}</span>
  </li>
</div>




<div>

<!-- <data-table>


</data-table> -->
<!-- 
 <thead>
                    <tr>
                      <th class="text-left">Quantity</th>
                      <th class="text-left">Unit</th>
                      <th class="text-center">Short Description</th>
                      <th class="text-center">Amount</th>
                  
                    </tr>
                  </thead>
                  <tbody>
                      
                    <tr v-for="(item, index) in warehouse" :key="index">
                      <td>{{ item.quantity }}</td>
                      <td>{{ item.unit }}</td>
                      <td>{{ item.product_title }}</td>
                      <td>{{ item.amount }}</td>
                      <td class="d-flex justify-center">
                      </td>
                    </tr>
                  </tbody> -->

</div>

<div>

</div>



<div>

 


<table id="example" class="table table-striped table-bordered" style="width:100%">
        <thead>
            <tr>
                <th>Quantity</th>
                <th>Unit</th>
                <th>Short Description</th>
                <th>Unit Price</th>
                <th>amount</th>
               
            </tr>
        </thead>
        <tbody>

           <tr>
                <td> {{quantity}}</td>
                <td>{{unit}}</td>
                <td>{{product_title}}</td>
                <td>{{unit_price}}</td>
                <td>{{amount}}</td>
                
            </tr>

        </tbody>
 </table>
</div>

<div>
  <li>
    <span>Sub Total:{{sub_total}}</span>
  </li>
  <li> <br>
    <span>Discount:{{discount}}</span>
  </li> <br>
   <li> <br>
    <span>VAT:{{vat}}</span>
  </li> <br>
  <li>
    <span>Grand Total:{{sub_total}}</span>
  </li> <br>

</div>
<div>
  <p>
    Payment Status: {{}}
  </p>
</div>
<div>
  <p>This Invoice Is Autogenareted By {{site_name}} After Confirming Your Order And Payment</p>
</div>






<!-- <table id="items">
		
		  <tr>
		      <th>Item</th>
		      <th>Description</th>
		      <th>Unit Cost</th>
		      <th>Quantity</th>
		      <th>Price</th>
		  </tr>
		  
		  <tr class="item-row">
		      <td class="item-name"><div class="delete-wpr"><textarea>Web Updates</textarea><a class="delete" href="javascript:;" title="Remove row">X</a></div></td>
		      <td class="description"><textarea>Monthly web updates for http://widgetcorp.com (Nov. 1 - Nov. 30, 2009)</textarea></td>
		      <td><textarea class="cost">$650.00</textarea></td>
		      <td><textarea class="qty">1</textarea></td>
		      <td><span class="price">$650.00</span></td>
		  </tr>
		  
		  <tr class="item-row">
		      <td class="item-name"><div class="delete-wpr"><textarea>SSL Renewals</textarea><a class="delete" href="javascript:;" title="Remove row">X</a></div></td>

		      <td class="description"><textarea>Yearly renewals of SSL certificates on main domain and several subdomains</textarea></td>
		      <td><textarea class="cost">$75.00</textarea></td>
		      <td><textarea class="qty">3</textarea></td>
		      <td><span class="price">$225.00</span></td>
		  </tr>
		  
		  <tr id="hiderow">
		    <td colspan="5"><a id="addrow" href="javascript:;" title="Add a row">Add a row</a></td>
		  </tr>
		  
		  <tr>
		      <td colspan="2" class="blank"> </td>
		      <td colspan="2" class="total-line">Subtotal</td>
		      <td class="total-value"><div id="subtotal">$875.00</div></td>
		  </tr>
		  <tr>

		      <td colspan="2" class="blank"> </td>
		      <td colspan="2" class="total-line">Total</td>
		      <td class="total-value"><div id="total">$875.00</div></td>
		  </tr>
		  <tr>
		      <td colspan="2" class="blank"> </td>
		      <td colspan="2" class="total-line">Amount Paid</td>

		      <td class="total-value"><textarea id="paid">$0.00</textarea></td>
		  </tr>
		  <tr>
		      <td colspan="2" class="blank"> </td>
		      <td colspan="2" class="total-line balance">Balance Due</td>
		      <td class="total-value balance"><div class="due">$875.00</div></td>
		  </tr>
		
		</table>



		<table id="items">
		
		  		   
		  <tr id="hiderow">
		    <td colspan="5"><a id="addrow" href="javascript:;" title="Add a row">Add a row</a></td>
		  </tr>
		  
		  <tr>
		      <td colspan="2" class="blank"> </td>
		      <td colspan="2" class="total-line">Subtotal</td>
		      <td class="total-value"><div id="subtotal">$875.00</div></td>
		  </tr>
		  <tr>

		      <td colspan="2" class="blank"> </td>
		      <td colspan="2" class="total-line">Total</td>
		      <td class="total-value"><div id="total">$875.00</div></td>
		  </tr>
		  <tr>
		      <td colspan="2" class="blank"> </td>
		      <td colspan="2" class="total-line">Amount Paid</td>

		      <td class="total-value"><textarea id="paid">$0.00</textarea></td>
		  </tr>
		  <tr>
		      <td colspan="2" class="blank"> </td>
		      <td colspan="2" class="total-line balance">Balance Due</td>
		      <td class="total-value balance"><div class="due">$875.00</div></td>
		  </tr>
		
		</table>
		
		<div id="terms">
		  <h5>Terms</h5>
		  <textarea>NET 30 Days. Finance Charge of 1.5% will be made on unpaid balances after 30 days.</textarea>
		</div> -->
	

	
</body>


  </v-container>
</template>


<script>
export default {
  data: () => ({
    snackbar: false,
    text: "",
    dialog: false,
    dialog2: false,
    question: "",
    ans: "",
    headers: [
      {
        text: "Question And Ans",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Question", value: "question", sortable: false },
      { text: "Ans", value: "ans", sortable: false },
      { text: "Date", value: "date", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    contract: [],
    contact: {},
    editedIndex: -1,

    editedItem: {
      question: "",
      ans: "",
    },

    defaultItem: {
      question: "",
      ans: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New FAQ" : "Edit FAQ";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialog2(val) {
      val || this.close();
    },
  },

  methods: {
    //     initialize() {
    //       axios.get("site/show_faq/").then((response) => {
    //         this.contract = response.data.data;
    //       });
    //     },
    //     addnew() {
    //       this.dialog2 = true;
    //     },
    //     confirm() {
    //       axios
    //         .post("site/insert_faq/", this.editedItem)
    //         .then((response) => {
    //           if (response.data.success) {
    //             this.contract.push(response.data.data);
    //             this.dialog = false;
    //             this.text = "Faq inserted successfully";
    //             this.snackbar = true;
    //             this.editedItem = {};
    //           }
    //         })
    //         .catch((err) => {
    //           this.dialog = false;
    //           this.text = "Something went wrong !";
    //           this.snackbar = true;
    //         });
    //     },
    //     editItem(item) {
    //       this.editedItem = item;
    //       this.dialog2 = true;
    //     },
    //     deleteItem(item) {
    //       if (confirm("Do you really want to delete?")) {
    //         axios.post(`site/delete_faq/${item.id}/`).then((response) => {
    //           this.contract = this.contract.filter(
    //             (contact) => contact.id !== item.id
    //           );
    //         });
    //       }
    //     },
    //     close() {
    //       this.dialog = false;
    //       this.dialog2 = false;
    //       this.$nextTick(() => {
    //         this.editedItem = Object.assign({}, this.defaultItem);
    //         this.editedIndex = -1;
    //       });
    //     },
    //     saveEdit() {
    //       axios
    //         .post(`site/specific_faq/${this.editedItem.id}/`, this.editedItem)
    //         .then((response) => {
    //           this.dialog2 = false;
    //           this.text = "Faq updated successfully";
    //           this.snackbar = true;
    //           this.editedItem = {};
    //         })
    //         .catch((err) => {
    //           this.dialog2 = false;
    //           this.text = "Something went wrong !";
    //           this.snackbar = true;
    //         });
    //     },
    //   },
    //   mounted() {
    //     this.initialize();
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>
